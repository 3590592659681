import Vue from 'vue';
import ElementUI from 'element-ui';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';

import * as message from '@/utils/messageHandler.js';
import * as SportLib from '@/utils/SportLib';
import * as lib from '@/utils/lib';
import * as conf from '@/config/index';
import '@/utils/OddDataList';

import 'element-ui/lib/theme-chalk/index.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'video.js/dist/video-js.css';
import '@/assets/sass/global.scss';
import '@/assets/sass/elementChange.scss';

Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });

Vue.config.productionTip = false;

Vue.prototype.$MSG = message;
Vue.prototype.$SportLib = SportLib;
Vue.prototype.$lib = lib;
Vue.prototype.$conf = conf;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
