<template>
  <div id="redirect" class="page">
    <main class="container" />
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {},
    beforeCreate() {
      console.log(this.$route.query);
    },
  };
</script>

<style lang="scss" scoped></style>
