import request from './config/request';
import i18n from '@/i18n';

// 取得遊戲球種
export function getGameCatApi() {
  return request({
    url: `/GameInfo/GameOnlyCatID/${i18n.locale}`,
    method: 'get',
  });
}

// 取得遊戲類別
export function getGameTypeApi() {
  return request({
    url: '/GameInfo/MenuGameType',
    method: 'post',
    auth: true,
  });
}

// 取得遊戲菜單
export function getGameMenuApi() {
  return request({
    url: '/GameInfo/Menu',
    method: 'post',
    auth: true,
  });
}

// 取得遊戲盤口
export function getGameDetailApi(data) {
  return request({
    url: '/GameInfo/GameDetail',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲盤口變動
export function getGameDetailSmallApi(data) {
  return request({
    url: '/GameInfo/GamelistSmall',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲盤口限額
export function getGameDetailLimitApi(data) {
  return request({
    url: '/GameInfo/MainBetInfo',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲投注資訊
export function getGamePlayInfo(data) {
  return request({
    url: '/GameInfo/BetInfo',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲賽果
export function getGameResultApi(data) {
  return request({
    url: '/GameInfo/GameResult',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲聯盟
export function getGameLeagueApi(data) {
  return request({
    url: '/GameInfo/GameResultLeagues',
    method: 'post',
    auth: true,
    data,
  });
}

// 遊戲投注
export function postPlayApi(list) {
  return request({
    url: '/GameInfo/Play',
    method: 'post',
    auth: true,
    data: { list },
  });
}

// 取得遊戲投注結果
export function getPlayResultApi(traceCodeKey) {
  return request({
    url: '/GameInfo/playState',
    method: 'post',
    auth: true,
    data: { traceCodeKey },
  });
}

// 取得遊戲注單
export function getTicketApi(data) {
  return request({
    url: '/GameInfo/Ticket/betHistory',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲注單統計
export function getTicketStatsApi(data) {
  return request({
    url: '/GameInfo/Ticket/betDayHistory',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得遊戲公告
export function getAnnouncementApi(data) {
  return request({
    url: '/GameInfo/Ann',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得聊天室預設文字
export function getChatTextApi(url) {
  return request({
    baseURL: url,
    url: '/outputApi/DefaultMes',
    method: 'get',
  });
}

// 取得聊天室表情圖示
export function getChatEmojiApi(url) {
  return request({
    baseURL: url,
    url: '/outputApi/EmojiInit',
    method: 'get',
  });
}

// 取得會員遊戲投注限制
export function getUserPlayLimitMenuApi() {
  return request({
    url: '/GameInfo/GetLimitGameMenu',
    method: 'post',
    auth: true,
  });
}

// 取得會員遊戲投注限制
export function getUserPlayLimitApi(CatID = 1) {
  return request({
    url: '/GameInfo/GetLimitGameTable',
    method: 'post',
    auth: true,
    data: { CatID },
  });
}
