export const I18N_LOCALE = 'Lang';

export const LOGIN_ACCOUNT_SAVE = 'x';
export const LOGIN_PASSWORD_SAVE = 'y';

export const SESSION_TOKEN = 'Token';
export const SESSION_ACCOUNT = 'MBID';

export const LOGIN = 't';
export const LOGOUT = 's';

export const WELCOME = 'welcome-closed';
export const PROMOTION = 'eventAD-closed';

// theme
export const THEME_MODE = 'Theme';
export const THEME_KEY = 'Theme2';
export const THEME_COLOR = 'ThemeColor';
