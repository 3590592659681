import { getTicketStatsApi, getTicketApi } from '@/api/game';
// import rootStore from '@/store';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getTicketStatsApi(store, postData) {
      return getTicketStatsApi(postData);
    },
    getTicketApi(store, postData) {
      return getTicketApi(postData);
    },
  },
};
