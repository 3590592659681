import { getChatTextApi, getChatEmojiApi } from '@/api/game';
import rootStore from '@/store';

export default {
  namespaced: true,
  state: {
    ChatList: [],
    isChatInputFocus: false,
    defaultMSG: [],
    emojiDataRaw: [],
    loginResultCode: -100,
    chatUrl: '',
  },
  getters: {
    emojiGroups(state) {
      const groups = state.emojiDataRaw.reduce((acc, it) => {
        const group = it.DirName || 'common';
        !acc[group] && (acc[group] = []);
        acc[group].push({
          base64Img: it.ImgName,
          symbol: it.Symbol,
          uniqueKey: '#' + group + it.Symbol,
        });
        return acc;
      }, {});
      return groups;
    },
  },
  mutations: {
    SetChatList(state, val) {
      state.ChatList.length = 0;
      state.ChatList = val;
    },
    setChatInputFocus(state, val) {
      state.isChatInputFocus = val;
    },
    setDefaultMSG(state, val) {
      state.defaultMSG = val;
    },
    setEmojiData(state, val) {
      state.emojiDataRaw = val;
    },
    PushChatList(state, val) {
      state.ChatList.push(val);
    },
    ClearChatList(state, val) {
      state.ChatList.length = 0;
      state.ChatList = [];
    },
    SetLoginResultCode(state, val) {
      state.loginResultCode = val;
    },

    initChatUrl(state) {
      const { ChatRoom } = rootStore.state?.siteInfo;
      if (ChatRoom) {
        state.chatUrl = 'https://' + ChatRoom;
      }
    },
  },
  actions: {
    getChatText({ state, commit }) {
      return new Promise((resolve, reject) => {
        return getChatTextApi(state.chatUrl)
          .then((res) => {
            if (res?.data) {
              commit('setDefaultMSG', res.data);
            }
            resolve();
          })
          .catch(reject);
      });
    },
    getChatEmoji({ state, commit }, val) {
      return new Promise((resolve, reject) => {
        return getChatEmojiApi(state.chatUrl)
          .then((res) => {
            if (res?.data) {
              commit('setEmojiData', res.data);
            }
            resolve();
          })
          .catch(reject);
      });
    },
  },
};
