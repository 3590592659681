export const favoriteCatID = 999999; // 收藏夾
export const hotCatID = 888888; // 熱門賽事
export const championCatID = 86; // 冠軍
export const raceCatID = 72; // 賽馬賽狗
export const lotteryCatID = 83; // 彩球
export const indexCatID = 84; // 指數

export const footballCatIDs = [1, 31]; // 足球, 世界盃(歐冠盃)
export const basketballCatIDs = [102, 3, 16]; // 籃球
export const baseballCatIDs = [101, 4, 11, 12, 13, 14]; // 棒球

export const handicapWagerIDs = [101, 103, 106, 121, 124, 129]; // 讓分
export const bigsmallWagerIDs = [102, 104, 109, 122, 125]; // 大小
export const oddevenWagerIDs = [105, 113, 126, 128]; // 單雙
export const winlosedrewWagerIDs = [110]; // 勝平負
export const winloseWagerIDs = [111, 123, 127]; // 獨贏
export const boldWagerIDs = [112]; // 波膽
export const totalWagerIDs = [115]; // 總入球
export const halffullWagerIDs = [116]; // 半全場
export const firstWagerIDs = [117]; // 搶首分
export const lastWagerIDs = [118]; // 搶尾分
export const goalWagerIDs = [119]; // 入球數
export const unboldWagerIDs = [120]; // 反波膽
export const teamtotalWagerIDs = [131, 132]; // 單隊總分大小, 單隊總分單雙
export const championWagerIDs = [133]; // 冠軍

export const noGroupIDs = [128];
export const fullGroupIDs = [0, 10, 20]; // 早盤全場, 今日全場, 滾球全場
export const halfGroupIDs = [1, 11, 21]; // 早盤上半, 今日上半, 滾球上半

export const strayMax = 10;

// 全場/上半 Menu
export function hasMenu(catID, wagerType) {
  // 足球 角球
  // 籃球 單隊總分
  return (
    (footballCatIDs.includes(catID) && [2].includes(wagerType)) ||
    (baseballCatIDs.includes(catID) && [6].includes(wagerType))
  );
}

// 波膽
export function isBoldWager(catID, wagerType) {
  // 足球
  return footballCatIDs.includes(catID) && [3].includes(wagerType);
}

// 單隊總分
export function isSingleTeamWager(catID, wagerType) {
  // 籃球
  return basketballCatIDs.includes(catID) && [6].includes(wagerType);
}
