const TOKEN = 'Token';
const ACCOUNT = 'MBID';
export const REDIRECT = 'redirect';
export const REDIRECTED = 'redirected';

export function getSessionToken() {
  return sessionStorage.getItem(TOKEN) || '';
}

export function setSessionToken(token) {
  sessionStorage.setItem(TOKEN, token);
}

export function clearSessionToken() {
  sessionStorage.removeItem(TOKEN);
}

export function getSessionAccount() {
  return sessionStorage.getItem(ACCOUNT) || '';
}

export function setSessionAccount(account) {
  sessionStorage.setItem(ACCOUNT, account);
}

export function clearSessionAccount() {
  sessionStorage.removeItem(ACCOUNT);
}

export function isTryRedirect() {
  return !!sessionStorage.getItem(REDIRECT);
}

export function setSessionRedirected() {
  return sessionStorage.setItem(REDIRECT, true);
}
