import { getGamePlayInfo, postPlayApi, getPlayResultApi, getTicketApi } from '@/api/game';
import { oddDataToPlayData } from '@/utils/SportLib';
import { PanelModeEnum } from '@/config/BetPanelMode';
import rootStore from '@/store';
const quickBetData = {
  isShow: false,
  x: 0,
  y: 0,
};
const lastFirstBetData = {
  message: '',
  // 會有三種狀態
  // init : 未下注狀態
  // done : 可以開啟quickBet
  // lock : 無法開啟quickBet
  // wait : 下注中
  state: 'init',
};
export default {
  namespaced: true,
  state: {
    // 購物車資料
    betCartList: [],
    tracingList: [],
    tracingCode: null,
    tracingBackup: {},
    isShowTracing: false,
    flag: false,
    // 歷史投注資料
    betHistoryList: [],
    // 歷史注單數量
    betHistoryListCount: 0,
    // 未結算歷史注單數量 (手機footer用)
    unsettledBetCount: 0,
    // 是否有新的注單加入到購物車
    isAddNewToChart: false,
    // 是否需要去清除BetViewList的資料
    isClearMemberData: false,
    // 快速投注,點擊投注按鈕會更改此狀態,BetViewList監聽此值,來決定是否直接呼叫SubmitHandler
    isSubmitHandler: false,
    // BetViewList監聽此值,來決定是否直接呼叫reCalcBetChart
    isReCalcBetCart: false,
    // 串關賠率
    strayOdd: null,
    // 下注Mode
    panelMode: PanelModeEnum.normal,
    // 快速下注資料
    quickBetData,
    // 投注Message,主要是給QuickBetPanel使用
    lastFirstBetData: Object.assign({}, lastFirstBetData),
    // 如果此值發生變化,ListCardItem,會去清除狀態
    triggerListCardItemClear: false,
    // 是否在初始投注面板,顯示投注狀態
    isShowStatusData: false,
    isShowStatusData2: false,
    // 是否在初始過關投注面板,顯示投注狀態
    isShowStrayStatusData: false,
    // 顯示x筆投注資訊異動
    tipAmount: 0,
    // 是否顯示接受變化並下注
    isShowAcceptTip: false,
  },
  getters: {
    showBetCartList(state) {
      return state.betCartList;
    },
    showBetHistoryList(state) {
      return state.betHistoryList;
    },
  },
  mutations: {
    triggerFlage(state) {
      state.flag = !state.flag;
    },
    setIsShowStatusData(state, val) {
      state.isShowStatusData = val;
    },
    setIsShowStatusData2(state, val) {
      state.isShowStatusData2 = val;
    },
    setIsReCalcBetCart(state, val) {
      state.isReCalcBetCart = val;
    },
    setIsShowStrayStatusData(state, val) {
      state.isShowStrayStatusData = val;
    },
    setBetCartList(state, val) {
      state.betCartList = val;
    },
    // 重制初始化投注 面板狀態
    resetStatus(state) {
      state.isShowStatusData = false;
      state.isShowStatusData2 = false;
      state.isShowStrayStatusData = false;
      state.tipAmount = 0;
      state.isShowAcceptTip = false;
    },
    setLastFirstBetDataMessage(state, val) {
      state.lastFirstBetData.message = val;
    },
    setLastFirstBetDataState(state, val) {
      state.lastFirstBetData.state = val;
    },
    clearLastFirstBetData(state) {
      state.lastFirstBetData = Object.assign({}, lastFirstBetData);
    },
    showQuickBetData(state, { isShow, x, y }) {
      state.quickBetData.isShow = isShow;
      state.quickBetData.x = x;
      state.quickBetData.y = y;
    },
    setPanelMode(state, val) {
      state.panelMode = val;
    },
    setIsClearMemberData(state) {
      state.isClearMemberData = !state.isClearMemberData;
    },
    setIsSubmitHandler(state) {
      state.isSubmitHandler = !state.isSubmitHandler;
    },
    setStrayOdd(state, val) {
      state.strayOdd = val;
    },
    pushCart(state, cartData) {
      state.betCartList.push(cartData);
    },
    clearCart(state) {
      state.betCartList = [];
      state.strayOdd = null;
    },
    clearCartBetResult(state) {
      state.betCartList.forEach((it) => {
        it.betResult = null;
        it.betResultCount = 0;
        it.hasDataChange = false;
        it.isSuccessMode = false;
      });
      state.quickBetData = quickBetData;
      state.triggerListCardItemClear = !state.triggerListCardItemClear;
    },
    removeCartByGameID(state, gameID) {
      const cartIndex = state.betCartList.findIndex((cartData) => cartData.GameID === gameID);
      if (cartIndex > -1) {
        state.betCartList.splice(cartIndex, 1);
      }
    },
    setBetHistoryList(state, list) {
      state.betHistoryList.length = 0;
      state.betHistoryList = list;
    },
    setBetHistoryListCount(state, count) {
      state.betHistoryListCount = count;
    },
    setUnsettledBetCount(state, count) {
      state.unsettledBetCount = count;
    },
    updateBetCartListBetResult(state, resultList) {
      if (state.betCartList.length !== 0 && state.panelMode === PanelModeEnum.result) {
        resultList.forEach((it, index) => {
          state.betCartList[index].betResult = it;
          ++state.betCartList[index].betResultCount;
        });
      }
    },
    updateBetCartListDataChangeByGameID(state, gameID) {
      const findIndex = state.betCartList.findIndex((it) => it.GameID === gameID);
      if (findIndex !== -1) {
        state.betCartList[findIndex].hasDataChange = true;
      }
    },
    updateBetCartListOriginShowOddByGameID(state, { gameID, newShowOdd }) {
      const findIndex = state.betCartList.findIndex((it) => it.GameID === gameID);
      if (findIndex !== -1) {
        state.betCartList[findIndex].OriginShowOdd = newShowOdd;
      }
    },
    setTracingCode(state, code) {
      state.tracingCode = code;
    },
    setShowTracing(state, show) {
      state.isShowTracing = show;
    },
    addTracing(state, copy = false) {
      const code = state.tracingCode;
      if (code) {
        const item = {
          running: true,
          status: 0,
          title: '投注中',
          backup: state.tracingBackup[code],
          code,
        };
        state.tracingList.push(item);
        state.tracingCode = null;
        delete state.tracingBackup[code];
        if (copy) {
          const list = state.betCartList;
          state.betCartList = [];
          list.forEach((i) => state.betCartList.push(Object.assign({}, i)));
        }
      }
    },
    removeTracing(state, code) {
      const index = state.tracingList.findIndex((item) => item.code === code);
      if (index !== -1) {
        state.tracingList.splice(index, 1);
      }
    },
    backupTracing(state, item) {
      state.tracingBackup[item.traceCodeKey] = item;
    },
    unbackupTracing(state, key) {
      if (key in state.tracingBackup) {
        delete state.tracingBackup[key];
      }
    },
    showTracingItem(state, item) {
      state.isShowTracing = true;
      state.betCartList = item.backup.list;
      state.panelMode = PanelModeEnum.result;
    },
  },
  actions: {
    // 获取投注盘口详情API
    callCartUpdateAPI(store, gameIDs) {
      return new Promise((resolve, reject) => {
        return getGamePlayInfo({ GameIDs: JSON.stringify(gameIDs) })
          .then(async (res) => {
            if (res.data.length !== 0) {
              res.data.forEach((apiData) => {
                const updateGameID = apiData.GameID;
                const updateCartIndex = store.state.betCartList.findIndex(
                  (betCart) => betCart.GameID === updateGameID
                );
                if (updateCartIndex > -1) {
                  store.state.betCartList[updateCartIndex] = {
                    ...store.state.betCartList[updateCartIndex],
                    apiUpdated: true,
                    BetMaxEvt: apiData.BetMaxEvt,
                    BetMaxPL: apiData.BetMaxPL,
                    BetMax: apiData.BetMax,
                    BetMin: apiData.BetMin,
                    Status: apiData.Status,
                    EvtStatus: apiData.EvtStatus,
                    AwayHdp: apiData.AwayHdp,
                    AwayHdpOdds: apiData.AwayHdpOdds,
                    AwayOdds: apiData.AwayOdds,
                    DrewOdds: apiData.DrewOdds,
                    HdpPos: apiData.HdpPos,
                    HomeHdp: apiData.HomeHdp,
                    HomeHdpOdds: apiData.HomeHdpOdds,
                    HomeOdds: apiData.HomeOdds,
                    OULine: apiData.OULine,
                    OverOdds: apiData.OverOdds,
                    UnderOdds: apiData.UnderOdds,
                    EnableParlay: apiData.EnableParlay,
                  };

                  if (apiData?.HomeScore !== undefined) {
                    store.state.betCartList[updateCartIndex].HomeScore = apiData.HomeScore;
                  }
                  if (apiData?.AwayScore !== undefined) {
                    store.state.betCartList[updateCartIndex].AwayScore = apiData.AwayScore;
                  }
                  store.state.betCartList[updateCartIndex].playData = oddDataToPlayData(
                    store.state.betCartList[updateCartIndex].SetFlag,
                    store.state.betCartList[updateCartIndex].WagerTypeID,
                    store.state.betCartList[updateCartIndex]
                  );
                }
              });
              store.state.betCartList = store.state.betCartList.slice();

              // 更新主玩法data
              rootStore.commit('Game/updateGameList', {
                isUpdateFromOtherStore: true,
                updateData: res.data,
              });

              // 更新更多玩法
              rootStore.commit('MoreGame/updateMoreGameData', {
                isUpdateFromOtherStore: true,
                updateData: res.data,
              });

              store.commit('setIsReCalcBetCart', !store.state.isReCalcBetCart);
            }
            resolve(res);
          })
          .catch(reject);
      });
    },
    // 更新購物車內所有注單賠率資訊 API
    updateAllCartData(store) {
      return new Promise((resolve, reject) => {
        if (store.state.betCartList.length !== 0) {
          const betCartListGameIDs = store.state.betCartList.map((cartData) => cartData.GameID);
          return store.dispatch('callCartUpdateAPI', betCartListGameIDs);
        } else {
          resolve();
        }
      });
    },
    // 加入到購物車
    addToCart(store, betData) {
      // 當在顯示結算面板時,點擊新的投注則會清空
      if (store.state.panelMode === PanelModeEnum.result) {
        store.commit('addTracing');
        store.commit('setShowTracing', false);
        store.commit('clearCart');
        store.commit('setPanelMode', PanelModeEnum.Normal);
        store.commit('setIsClearMemberData');
      }

      // 是否在購物車內找到完全相同的自己
      const isSelfJustRemove =
        store.state.betCartList.findIndex(
          (cartData) => cartData.GameID === betData.GameID && cartData.wagerPos === betData.wagerPos
        ) > -1;

      // 移除相同的game id
      store.commit('removeCartByGameID', betData.GameID);

      // 如果購物車內找到相同的自己,移除後就直接結束function
      if (isSelfJustRemove) {
        return;
      }

      let newBetData = JSON.parse(JSON.stringify(betData));
      let betAmount = null;
      if (rootStore.state.Game.isQuickBet.isEnable) {
        betAmount = parseInt(rootStore.state.Game.isQuickBet.amount);
      } else if (
        rootStore.state.Setting.UserSetting.defaultAmount.type === 1 ||
        rootStore.state.Setting.UserSetting.defaultAmount.type === 2
      ) {
        betAmount = parseInt(rootStore.state.Setting.UserSetting.defaultAmount.amount);
      }

      let BetMaxEvt = null;
      let BetMaxPL = null;
      let BetMax = null;
      let BetMin = null;
      const { GameTypeID, CatID, WagerTypeID, WagerGrpID } = betData;
      const betInfoList = rootStore.state.Game.betInfo;
      let findBetInfoData = null;
      if (Array.isArray(betInfoList)) {
        findBetInfoData = betInfoList.find((it) => {
          return (
            it.GameType === GameTypeID &&
            it.CatID === CatID &&
            it.WagerTypeID === WagerTypeID &&
            it.WagerGrpID === WagerGrpID
          );
        });
      }
      if (findBetInfoData) {
        BetMaxEvt = findBetInfoData.BetMaxEvt;
        BetMaxPL = findBetInfoData.BetMaxPL;
        BetMax = findBetInfoData.BetMax;
        BetMin = findBetInfoData.BetMin;
      }

      newBetData = {
        HomeScore: undefined,
        AwayScore: undefined,
        ...newBetData,
        BetMaxEvt,
        BetMaxPL,
        BetMax,
        BetMin,
        betAmount,
        winAmount: null,
        betResult: null,
        betResultCount: 0,
        isShowMinText: false,
        isShowMaxText: false,
        // 資料是否異動
        hasDataChange: false,
        // 初始面板顯示的錯誤訊息
        statusMessage: '',
        // 如果是成功mode做任何操作將會刪掉自己
        isSuccessMode: false,
        // 如果此值會true,強制顯示"盤口關閉中,請移除再下注",此值只要設為true,就不會再更改
        isForceLock: false,
        EnableParlay: 1,
        apiUpdated: false,
      };

      newBetData.playData = oddDataToPlayData(betData.SetFlag, newBetData.WagerTypeID, newBetData);
      store.commit('pushCart', newBetData);
      store.state.isAddNewToChart = !store.state.isAddNewToChart;
      store.dispatch('callCartUpdateAPI', [betData.GameID]);
    },
    // 執行投注 API ,
    // betType : 1  一般投注
    // betType : 99 過關投注
    submitBet(store, list) {
      return new Promise((resolve, reject) => {
        store.commit('setLoading', true, { root: true });
        return postPlayApi(list)
          .then((res) => {
            const balance = res.data?.Balance;
            if (balance !== undefined && balance !== null && balance !== '') {
              rootStore.commit('setUserBalance', balance);
            }
            if (res.data?.ticket) {
              store.commit('updateBetCartListBetResult', res.data.ticket);
            }
            rootStore.commit('BetCart/setUnsettledBetCount', res.data?.TicketCount || 0);
            resolve(res);
          })
          .catch(reject);
      });
    },
    checkBetState(store, { data, traceCodeKey, isStray = false }) {
      if (data) {
        const isFind200 = data.find((it) => it.code === 200);
        const isFind201 = data.find((it) => it.code === 201);
        const isFindNegative = data.find((it) => it.code < 0);
        const findNot200Amount = data.filter((it) => it.code !== 200).length;
        const isNeedShowAcceptTip = data.find((it) => it.code <= -3000 && it.code >= -3999);

        // 如果有找到201 就重新打一次playState
        if (isFind201) {
          setTimeout(() => {
            store.dispatch('getPlayResultApi', { traceCodeKey, isStray });
          }, 2000);
        } else {
          store.commit('triggerFlage');
          if (store.state.tracingCode === traceCodeKey) {
            store.state.tracingCode = null;
            store.commit('unbackupTracing', traceCodeKey);
          } else {
            const item = store.state.tracingList.find((item) => item.code === traceCodeKey);
            if (item) {
              item.running = false;
              item.data = data;
              if (isFindNegative) {
                item.status = 2;
                if (isFind200) item.title = '部分失敗';
                else item.title = '投注失敗';
                const list = item.backup.list;
                data.forEach((it, index) => {
                  if (it.code === 200) {
                    list[index].isSuccessMode = true;
                  } else if (it.code <= -2000 && it.code >= -2999) {
                    list[index].isForceLock = true;
                  }
                  list[index].statusMessage = it.Message;
                });
              } else {
                // test
                // item.status = 4
                // item.title = '測試';
                //
                item.status = 1;
                item.title = '投注成功';
                store.commit('unbackupTracing', traceCodeKey);
                setTimeout(() => {
                  store.commit('removeTracing', traceCodeKey);
                }, 2000);
              }
              return;
            }
          }
        }
        if (store.state.isShowTracing) return;

        let updateBet = false;
        data.forEach((it, index) => {
          if (it.code === 200) {
            store.state.betCartList[index].isSuccessMode = true;
          }
          if (it.code <= -2000 && it.code >= -2999) {
            store.state.betCartList[index].isForceLock = true;
          }
          if (it.code <= -3000 && it.code >= -3999) {
            updateBet = true;
          }
          store.state.betCartList[index].statusMessage = it.Message;
        });

        if (updateBet) {
          setTimeout(() => {
            store.dispatch('updateAllCartData');
          }, 2000);
        }

        // 如果沒有201 就是已經取得所有下注的狀態
        // 如果完全沒有成功的結果,則讓panelMode回到normal
        // 需求圖
        // https://apidoc.cc777.co/web/#/42/986
        if (isFindNegative && !isStray) {
          store.commit('setPanelMode', PanelModeEnum.normal);
          store.state.isShowStatusData = true;
          store.state.tipAmount = findNot200Amount;
          store.state.isReCalcBetCart = !store.state.isReCalcBetCart;

          const show2 = data.find((it) => it.code < 0 && (it.code >= -4000 || it.code < -4999));
          store.commit('setIsShowStatusData2', show2);
        }

        if (isFindNegative && isStray) {
          store.commit('setPanelMode', PanelModeEnum.normal);
          store.state.isShowStrayStatusData = true;
          store.state.isReCalcBetCart = !store.state.isReCalcBetCart;
        }

        if (isNeedShowAcceptTip) {
          store.state.isShowAcceptTip = true;
        } else {
          store.state.isShowAcceptTip = false;
        }

        store.commit('updateBetCartListBetResult', data);
        if (data.length !== 0) {
          store.commit('setLastFirstBetDataMessage', data[0]?.Message);
          if (!isFind201) {
            store.commit('setLastFirstBetDataState', 'done');
          }
        }
      } else {
        store.commit('setLastFirstBetDataState', 'done');
      }
    },
    checkBetState2(store, { data, traceCodeKey, isStray = false }) {
      if (data) {
        // const isFind201 = data.find((it) => it.code === 201);
        const isFindNegative = data.find((it) => it.code < 0);
        const findNot200Amount = data.filter((it) => it.code !== 200).length;
        const isNeedShowAcceptTip = data.find((it) => it.code <= -3000 && it.code >= -3999);
        data.forEach((it, index) => {
          if (it.code === 200) {
            store.state.betCartList[index].isSuccessMode = true;
          }
          if (it.code <= -2000 && it.code >= -2999) {
            store.state.betCartList[index].isForceLock = true;
          }
          store.state.betCartList[index].statusMessage = it.Message;
        });

        // 如果沒有201 就是已經取得所有下注的狀態
        // 如果完全沒有成功的結果,則讓panelMode回到normal
        // 需求圖
        // https://apidoc.cc777.co/web/#/42/986
        if (isFindNegative && !isStray) {
          store.commit('setPanelMode', PanelModeEnum.normal);
          store.state.isShowStatusData = true;
          store.state.tipAmount = findNot200Amount;
          store.state.isReCalcBetCart = !store.state.isReCalcBetCart;

          const show2 = data.find((it) => it.code < 0 && (it.code >= -4000 || it.code < -4999));
          store.commit('setIsShowStatusData2', show2);
        }

        if (isFindNegative && isStray) {
          store.commit('setPanelMode', PanelModeEnum.normal);
          store.state.isShowStrayStatusData = true;
          store.state.isReCalcBetCart = !store.state.isReCalcBetCart;
        }

        if (isNeedShowAcceptTip) {
          store.state.isShowAcceptTip = true;
        } else {
          store.state.isShowAcceptTip = false;
        }

        store.commit('updateBetCartListBetResult', data);
      } else {
      }
    },
    checkBetState3(store, { data, traceCodeKey, isStray = false }) {
      if (data) {
        // const isFind201 = data.find((it) => it.code === 201);
        const isFindNegative = data.find((it) => it.code < 0);
        const findNot200Amount = data.filter((it) => it.code !== 200).length;
        const isNeedShowAcceptTip = data.find((it) => it.code <= -3000 && it.code >= -3999);

        data.forEach((it, index) => {
          if (it.code === 200) {
            store.state.betCartList[index].isSuccessMode = true;
          }
          if (it.code <= -2000 && it.code >= -2999) {
            store.state.betCartList[index].isForceLock = true;
          }
          store.state.betCartList[index].statusMessage = it.Message;
        });

        if (isFindNegative && !isStray) {
          store.commit('setPanelMode', PanelModeEnum.normal);
          store.state.isShowStatusData = true;
          store.state.tipAmount = findNot200Amount;
          store.state.isReCalcBetCart = !store.state.isReCalcBetCart;

          const show2 = data.find((it) => it.code < 0 && (it.code >= -4000 || it.code < -4999));
          store.commit('setIsShowStatusData2', show2);
        }

        if (isFindNegative && isStray) {
          store.commit('setPanelMode', PanelModeEnum.normal);
          store.state.isShowStrayStatusData = true;
          store.state.isReCalcBetCart = !store.state.isReCalcBetCart;
        }

        if (isNeedShowAcceptTip) {
          store.state.isShowAcceptTip = true;
        } else {
          store.state.isShowAcceptTip = false;
        }

        store.commit('updateBetCartListBetResult', data);
        console.log(store.state);
      }
    },
    // 檢查投注狀態
    getPlayResultApi(store, { traceCodeKey, isStray = false }) {
      return new Promise((resolve, reject) => {
        return getPlayResultApi(traceCodeKey)
          .then((res) => {
            store.dispatch('checkBetState', { data: res?.data, traceCodeKey, isStray });
            rootStore.commit('setUserBalance', res?.Balance);
            rootStore.commit('BetCart/setUnsettledBetCount', res?.TicketCount || 0);
            resolve(res);
          })
          .catch(() => {
            store.commit('setLastFirstBetDataState', 'done');
          });
      });
    },
    getTicketApi(store, postData) {
      return new Promise((resolve, reject) => {
        return getTicketApi(postData)
          .then((res) => {
            if (res.data?.list) {
              // 歷史注單
              store.commit(
                'setBetHistoryList',
                res.data.list.map((it) => {
                  return {
                    ...it,
                    isCollapse: false,
                  };
                })
              );
            }
            // 歷史注單數量
            if (!postData.isset) {
              store.commit('setBetHistoryListCount', res.data?.Count || 0);
            }
            resolve(res);
          })
          .catch(reject);
      });
    },
  },
};
