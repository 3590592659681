import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import APILogin from '@/views/APILogin.vue';
import ModifyPWD from '@/views/ModifyPWD.vue';
import Limits from '@/views/Limits.vue';
import Redirect from '@/views/Redirect.vue';

Vue.use(VueRouter);

const uiMode = process.env.VUE_APP_UI;

export const redirectRoute = {
  path: '/Redirect',
  name: 'Redirect',
  component: Redirect,
};

export const loginRoute = {
  path: '/Login',
  name: 'Login',
  component: Login,
};

export const apiLoginRoute = {
  path: '/APILogin',
  name: 'APILogin',
  component: APILogin,
};

export const passwordRoute = {
  path: '/ModifyPWD',
  name: 'ModifyPWD',
  component: ModifyPWD,
};

export const limitsRoute = {
  path: '/Limits',
  name: 'Limits',
  component: Limits,
};

export const gameRoute = {
  path: '/Games',
  name: 'Games',
  component: () => {
    switch (uiMode) {
      case 'pc':
        return import(/* webpackChunkName: "chunk-pc" */ '@/views/pc/Games.vue');
      default:
      case 'mobile':
        return import(/* webpackChunkName: "chunk-mobile" */ '@/views/mobile/mGames.vue');
    }
  },
};

const gameResultRoute = {
  path: '/GameResult',
  name: 'GameResult',
  component: () => import('@/views/pc/GameResult.vue'),
};

const newsRoute = {
  path: '/News',
  component: () => import(/* webpackChunkName: "chunk-news" */ '@/views/news/News.vue'),
  children: [
    {
      path: '',
      redirect: 'news',
    },
    // {
    //  path: 'home',
    //  name: 'NewsHome',
    //  component: () => import(/* webpackChunkName: "chunk-news" */ '@/views/news/NewsHome.vue'),
    // },
    // {
    //  path: 'superpig',
    //  name: 'NewsSuperPig',
    //  component: () => import(/* webpackChunkName: "chunk-news" */ '@/views/news/NewsSuperPig.vue'),
    // },
    // {
    //  path: 'super8',
    //  name: 'NewsSuper8',
    //  component: () => import(/* webpackChunkName: "chunk-news" */ '@/views/news/NewsSuper8.vue'),
    // },
    {
      path: 'news',
      name: 'NewsNews',
      component: () => import('@/views/news/NewsNews.vue'),
    },
    {
      path: 'best',
      name: 'NewsBest',
      component: () => import('@/views/news/NewsBest.vue'),
    },
    // {
    //  path: 'schedule',
    //  name: 'NewsSchedule',
    //  component: () => import(/* webpackChunkName: "chunk-news" */ '@/views/news/NewsSchedule.vue'),
    // },
  ],
};

const routes = [
  {
    path: '/',
    redirect: loginRoute.path,
  },
  redirectRoute,
  loginRoute,
  apiLoginRoute,
  passwordRoute,
  limitsRoute,
  gameRoute,
  gameResultRoute,
  newsRoute,
];

if (uiMode === 'pc') {
  const historyRecordRoute = {
    path: '/HistoryRecord',
    name: 'HistoryRecord',
    component: () => import(/* webpackChunkName: "chunk-pc" */ '@/views/pc/HistoryRecord.vue'),
  };

  const announcementRoute = {
    path: '/Ann',
    name: 'Ann',
    component: () => import(/* webpackChunkName: "chunk-pc" */ '@/views/pc/Ann.vue'),
  };

  routes.push(historyRecordRoute, announcementRoute);
}

routes.push({
  path: '/*',
  redirect: '/',
});

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
      // behavior: 'smooth',
    };
  },
});

export default router;
