import { THEME_MODE, THEME_KEY, THEME_COLOR } from './storage';

export const themeModes = ['light', 'dark'];

export const themeMap = {
  c1: { key: 'c1', light: '#3fa381', dark: '#2e7f63' },
  c2: { key: 'c2', light: '#4a7380', dark: '#2b444e' },
  c3: { key: 'c3', light: '#6f78e2', dark: '#383a82' },
  c4: { key: 'c4', light: '#3d51a3', dark: '#20315d' },
  c5: { key: 'c5', light: '#d42a58', dark: '#7a0e2d' },
  c6: { key: 'c6', light: '#e0496c', dark: '#812138' },
  c7: { key: 'c7', light: '#b4935f', dark: '#695231' },
  c8: { key: 'c8', light: '#efb229', dark: '#a37418' },
};

export const themeKeys = Object.keys(themeMap);

let themeMode = localStorage.getItem(THEME_MODE);
if (!themeMode || !themeModes.includes(themeMode)) {
  themeMode = themeModes[0];
}

let themeKey = localStorage.getItem(THEME_KEY);
if (!themeKey || !themeKeys.includes(themeKey)) {
  themeKey = themeKeys[0];
}

export function getTheme() {
  return themeMap[themeKey];
}

export function getThemeMode() {
  return themeMode;
}

export function getThemeKey() {
  return themeKey;
}

export function getThemeColor() {
  return themeMap[themeKey][themeMode];
}

export function setThemeMode(mode) {
  if (mode && mode !== themeMode && themeModes.includes(mode)) {
    themeMode = mode;
    localStorage.setItem(THEME_MODE, themeMode);
    localStorage.setItem(THEME_COLOR, themeMap[themeKey][themeMode]);
  }
  return themeMode;
}

export function setThemeKey(key) {
  if (key && key !== themeKey && themeKeys.includes(key)) {
    themeKey = key;
    localStorage.setItem(THEME_KEY, themeKey);
    localStorage.setItem(THEME_COLOR, themeMap[themeKey][themeMode]);
  }
  return themeKey;
}
