// 賽事資訊

import request from './config/request';

// 取得賽事新聞
export function getSportNewsApi({ pageIndex = 1, pageSize = 10 } = {}) {
  const data = { Pagesize: pageSize, Currentpage: pageIndex };
  return request({
    url: '/GameInfo/SportNews/List',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得賽事新聞內容
export function getSportNewsContentApi(newsId) {
  const data = { ArticleId: newsId };
  return request({
    url: '/GameInfo/SportNews/Detail',
    method: 'post',
    auth: true,
    data,
  });
}

// 取得賽事影片(十大好球)
export function getSportVideoApi() {
  return request({
    url: '/GameInfo/SportNews/Video',
    method: 'post',
    auth: true,
  });
}

// 取得賽事直播連結
export function getSportLiveApi() {
  return request({
    url: '/GameInfo/Live/XCFWD_3',
    method: 'post',
    auth: true,
  });
}

// 取得影城連結
export function getMovieTheaterApi() {
  return request({
    url: '/GameInfo/Live/XCFWDMOVE_	',
    method: 'post',
    auth: true,
  });
}
