<template>
  <div id="PersonalPanel" @click="onMaskClick">
    <div class="popup">
      <div class="header">
        <div class="title"> {{ $t('GamesHeader.PersonalSetting') }} </div>
        <div v-if="!isSingleMode" class="btn-close" @click="onMaskClick" />
      </div>
      <div class="body">
        <div
          v-if="type === 2 && userNewAccount && expire"
          style="font-size: 16px; text-align: center"
        >
          你的新帳號名稱為: <span style="color: red">{{ userNewAccount }}</span>
        </div>
        <div
          v-if="type === 2 && userNewAccount && expire"
          style="font-size: 16px; text-align: center; color: red"
        >
          (請記下新帳號,密碼修改完畢後須使用新帳號登入)
        </div>
        <h2 style="text-align: center"> {{ title }} </h2>
        <template v-if="type === 1">
          <div class="row">
            <div class="rowTitle"> {{ $t('Login.Account') }} </div>
            <div class="rowContent accountBlock">
              {{ userAccount }}
            </div>
          </div>
          <div class="row">
            <div class="rowTitle"> {{ $t('Common.NickName') }} </div>
            <div class="rowContent">
              <el-input
                v-model="nickName"
                :suffix-icon="inputIcon"
                @focus="inputFocus"
                @blur="inputBlur"
              />
            </div>
          </div>
          <div class="row">
            <div class="rowTitle"> {{ $t('Common.Lang') }} </div>
            <div class="rowContent">
              <el-select v-model="lang">
                <el-option
                  v-for="item in langOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
        </template>
        <template v-else-if="type === 2">
          <div class="row">
            <div class="rowTitle"> {{ $t('Common.OldPassword') }} </div>
            <input v-model="oldPwd" type="password" class="input" autocomplete="new-password" />
          </div>
          <div class="row">
            <div class="rowTitle"> {{ $t('Common.NewPassword') }} </div>
            <input v-model="newPwd" type="password" class="input" />
          </div>
          <div class="row">
            <div class="rowTitle"> {{ $t('Common.ConfirmPassword') }} </div>
            <input v-model="confirmPwd" type="password" class="input" />
          </div>
        </template>
        <div class="row lastRow">
          <div v-if="!isSingleMode" class="cancelBtn" @click="$emit('closeMe')">
            {{ $t('Common.Cancel') }}
          </div>
          <div class="submitBtn" @click="modifyClick"> {{ $t('Common.Modify') }} </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import i18n, { setI18nLocale } from '@/i18n';

  export default {
    props: {
      type: {
        type: Number,
        default: 1,
      },
      isSingleMode: {
        type: Boolean,
        default: false,
      },
      expire: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        nickName: '',
        lastNickName: '',
        lang: 'tw',
        lastLang: '',
        langOptions: [
          {
            value: 'tw',
            label: '繁體中文',
          },
          {
            value: 'cn',
            label: '简体中文',
          },
          {
            value: 'en',
            label: 'English',
          },
        ],
        isFocus: false,
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
      };
    },
    mounted() {
      if (this.userName) {
        this.nickName = this.userName;
        this.lastNickName = this.nickName;
      }
      this.lang = i18n.locale;
      this.lastLang = this.lang;
      this.oldPwd = this.newPwd = this.confirmPwd = '';
    },
    computed: {
      ...mapGetters(['userName', 'userAccount', 'userNewAccount']),
      inputIcon() {
        if (this.isFocus) {
          return 'el-icon-success';
        } else {
          return '';
        }
      },
      title() {
        return this.type === 1 ? this.$t('Common.Profile') : this.$t('Common.ChangePassword');
      },
    },
    methods: {
      setNewLang(lang) {
        setI18nLocale(lang);
        location.reload();
      },
      onMaskClick(e) {
        if (e.target !== e.currentTarget || this.isSingleMode) return;
        this.$emit('closeMe');
      },
      inputFocus() {
        this.isFocus = true;
      },
      inputBlur() {
        this.isFocus = false;
      },
      clearInput() {
        this.oldPwd = '';
        this.newPwd = '';
        this.confirmPwd = '';
      },
      modifyClick() {
        if (this.type === 1) {
          if (this.lastNickName !== this.nickName) {
            this.$store.commit('setLoading', true);
            this.$store
              .dispatch('changeUserName', this.nickName)
              .then((res) => {
                this.lastNickName = this.nickName;
                this.$notify({
                  type: 'success',
                  title: this.$t('Common.Success'),
                  message: this.$t('Common.ModifySuccess'),
                });
                if (this.$store.state.Chat.loginResultCode === -105) {
                  window.chat && window.chat.APILoginMB();
                }
                this.$emit('closeMe');
              })
              .catch(() => {
                this.nickName = this.lastNickName;
              })
              .finally(() => {
                this.$store.commit('setLoading', false);
              });
          }
        } else if (this.type === 2) {
          const { oldPwd, newPwd, confirmPwd } = this;
          if (!oldPwd || !newPwd || !confirmPwd) return;
          if (oldPwd && newPwd !== confirmPwd) {
            this.clearInput();
            this.$notify({
              type: 'error',
              title: this.$t('Common.Error'),
              message: this.$t('Common.PasswordNotMatch'),
            });
            return;
          }
          if (!/^[A-Za-z0-9]{6,12}$/.test(newPwd)) {
            this.$notify({
              type: 'error',
              title: this.$t('Common.Error'),
              message: this.$t('Common.PasswordNotOK'),
            });
            return;
          }
          if (!/^[A-Za-z0-9]{6,12}$/.test(confirmPwd)) {
            this.$notify({
              type: 'error',
              title: this.$t('Common.Error'),
              message: this.$t('Common.PasswordNotOK2'),
            });
            return;
          }

          this.$store.commit('setLoading', true);
          this.$store
            .dispatch('changeUserPassword', {
              newPassword: newPwd,
              oldPassword: oldPwd,
            })
            .then((res) => {
              if (res.code === 200) {
                this.oldPwd = this.newPwd = this.confirmPwd = '';
                this.$notify({
                  type: 'success',
                  title: this.$t('Common.Success'),
                  message: this.$t('common.modifyPWD_SUCCESS'),
                });
                this.$store.commit('clearSession');
                this.$router.replace({ name: 'Login' });
                // sessionStorage.clear();
                // this.$router.replace({ name: 'Login' });
              } else {
                throw new Error();
              }
            })
            .catch(() => {
              this.clearInput();
            })
            .finally(() => {
              this.$store.commit('setLoading', false);
            });
        }
        if (this.lastLang !== this.lang) {
          this.setNewLang(this.lang);
        }
      },
    },
  };
</script>

<style lang="scss">
  .el-input__suffix {
    .el-input__icon {
      font-size: 18px;
      cursor: pointer;
    }
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';
  #PersonalPanel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .popup {
      width: calc(100vw - 50px);
      max-width: 350px;
      max-height: 80%;
      background-color: #eee;
      overflow: hidden;

      .header {
        @include base-background();
        position: relative;
        .title {
          color: #fff;
          font-size: 1.2rem;
          text-align: center;
          height: 40px;
          line-height: 40px;
        }
        .btn-close {
          position: absolute;
          top: 0;
          right: 0;
          height: 40px;
          width: 40px;
          background: url('~@/assets/img/pc/btn_close_w.svg') no-repeat center;
          background-size: 13px;
          opacity: 0.5;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
        .btn-help {
          cursor: pointer;
          font-family: Arial;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto 8px;
          border-radius: 50%;
          color: #fff;
          background-color: #136146;
          height: 25px;
          width: 25px;
          line-height: 26px;
          text-align: center;
          font-size: 17px;
          font-weight: bold;
        }
      }
      .line {
        height: 1px;
        background: #ccc;
        margin: 0 1.5rem;
      }
      .body {
        display: flex;
        flex-direction: column;
        max-height: 70%;
        overflow: auto;
        padding: 15px 20px;
        .row {
          display: flex;
          height: 40px;
          margin-bottom: 15px;
          justify-content: center;
          &:last-child {
            margin-bottom: 0px;
          }
          .rowTitle {
            font-size: 18px;
            color: gray;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .rowContent {
            width: calc(100% - 100px);
          }
          .accountBlock {
            background-color: rgb(204, 200, 200);
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(110, 110, 110);
            font-size: 18px;
          }

          .cancelBtn,
          .submitBtn {
            border-radius: 10px;
            color: white;
            font-size: 16px;
            line-height: 40px;
            padding: 0 20px;
            cursor: pointer;
          }
          .cancelBtn {
            background-color: #808080;
            margin-right: 15px;
            //&:hover {
            // background-color: #8a8a8a;
            //}
          }
          .submitBtn {
            @include base-background();
            // background-color: #41a780;
            margin-left: 15px;
            &:hover {
              @include base-background();
              //background-color: #43bb8d;
            }
          }
        }
        .lastRow {
          margin: 5px 0 !important;
        }

        .input {
          font-size: 14px;
          padding: 0 1rem;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
        }
      }
    }
  }
</style>
