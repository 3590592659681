import request from './config/request';

// 取得網站資訊
export function getSiteInfoApi() {
  return request({
    url: `/datafresh/WebSitInfoPost`,
    method: 'post',
  });
}

// 取得網站時間
export function getSiteTimeApi() {
  return request({
    url: `/datafresh/systimePost`,
    method: 'post',
  });
}

// 取得用戶資訊
export function getUserInfoApi() {
  return request({
    url: `/mb/info/about`,
    method: 'post',
    auth: true,
  });
}

// 取得用戶餘額
export function getUserBalanceApi() {
  return request({
    url: `/mb/info/cash`,
    method: 'post',
    auth: true,
  });
}

// 更改用戶密碼
export function putUserPasswordApi({ newPassword, oldPassword }) {
  const data = { pw: newPassword, oldpw: oldPassword };
  return request({
    url: `/mb/sin/repw`,
    method: 'post',
    auth: true,
    data,
  });
}

// 更改用戶名稱
export function putUserNameApi(name) {
  const data = { Nickname: name };
  return request({
    url: `/mb/info/setNickname`,
    method: 'post',
    auth: true,
    data,
  });
}

// 登入
export function postLoginApi({ account, password }) {
  const data = { mbID: account, pw: password };
  return request({
    url: `/mb/sin/login`,
    method: 'post',
    data,
  });
}

// token 登入
export function postTokenLoginApi(token) {
  const data = { MemID: token };
  return request({
    url: `/mb/sin/outApiLogin`,
    method: 'post',
    data,
  });
}

// 登出
export function postLogoutApi() {
  return request({
    url: `/mb/sin/logout`,
    method: 'post',
    auth: true,
  });
}
