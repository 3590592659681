// 不檢查WagerGrp和WagerTypeKey的WagerGrpID列表
export const NotCheckWagerGrpIDs = [128];

// 波膽 的 '其他' 玩法key name
export const BoldOtherKeyName = 'other';

// 收藏的CatID
export const favoriteCatID = 999999;

export const hotCatID = 888888;

// 結算時顯示特殊列表(ex. 賽馬、指數、彩球)
export const settlementSpecial = [72, 84, 83];

// 單隊總得分 WagerTypeID
export const totalScoreWagerTypeID = [131, 132];

// 最大過關數
export const maxStray = 10;

// 全場Grp ID
export const fullGameGrpID = [0, 10, 20];

export const halfGameGrpID = [1, 11, 21];

export const championWagerIDs = [133];
export const championWagerKey = 86;
export const championCatIDs = [13];

export const isMobileMode = process.env.VUE_APP_UI === 'mobile';
export const isEventMode = true; // WBC

export const appVersion = '20230323b';
