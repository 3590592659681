import Vue from 'vue';
import Vuex from 'vuex';
import Game from './modules/Game';
import MoreGame from './modules/MoreGame';
import BetCart from './modules/BetCart';
import Setting from './modules/Setting';
import History from './modules/History';
import Chat from './modules/Chat';
import router, { loginRoute } from '@/router';
import {
  getSiteInfoApi,
  getUserInfoApi,
  getUserBalanceApi,
  putUserPasswordApi,
  putUserNameApi,
  postLoginApi,
  postTokenLoginApi,
  postLogoutApi,
} from '@/api';
import { getI18nLocale, setI18nLocale } from '@/i18n';
import {
  getSessionToken,
  setSessionToken,
  clearSessionToken,
  getSessionAccount,
  setSessionAccount,
  clearSessionAccount,
} from '@/config/session';
import { LOGIN, LOGOUT } from '@/config/storage';
import { getThemeMode, setThemeMode, getThemeKey, setThemeKey } from '@/config/theme';
import { CASINO_SITE_LABEL } from '@/config/site';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { Game, BetCart, MoreGame, Setting, History, Chat },
  state: {
    isLoading: false,
    siteInfo: null,
    siteLabels: [],
    userInfo: null,
    userBalance: '',
    userTickets: 0,
    userUnread: 0,
    sessionToken: getSessionToken(),
    sessionAccount: getSessionAccount(),
    i18nLocale: getI18nLocale(),
    themeMode: getThemeMode(),
    themeKey: getThemeKey(),
  },
  getters: {
    isSiteOK(state) {
      return state.siteInfo !== null;
    },
    isCasinoSite(state) {
      return state.siteLabels.includes(CASINO_SITE_LABEL);
    },
    isCreditSite(state) {
      return !state.siteLabels.includes(CASINO_SITE_LABEL);
    },
    isSiteMaintain(state) {
      const value = state.siteInfo?.MainTain?.Status;
      if (typeof value === 'undefined') return false;
      return value !== 1;
    },
    siteMaintainMessage(state) {
      const value = state.siteInfo?.MainTain?.Message;
      if (typeof value === 'undefined') return '';
      return value;
    },
    siteLogo(state) {
      const value = state.siteInfo?.MainLogoPath;
      if (typeof value === 'undefined') return '';
      return value;
    },
    siteLoginLogo(state) {
      const value = state.siteInfo?.LoginLogoPath;
      if (typeof value === 'undefined') return '';
      return value;
    },
    siteApiVersion(state) {
      const value = state.siteInfo?.Ver;
      if (typeof value === 'undefined') return '';
      return value;
    },
    isUserOk(state) {
      return state.userInfo !== null;
    },
    userAccount(state) {
      const value = state.userInfo?.mbID;
      if (typeof value === 'undefined') return '';
      return value;
    },
    //
    userNewAccount(state) {
      const value = state.userInfo?.mbID;
      if (typeof value === 'undefined') return '';
      return value;
    },
    isUserPasswordExpire(state) {
      const value = state.userInfo?.pwExpire;
      if (typeof value === 'undefined') return false;
      return value;
    },
    userPasswordExpireMessage(state) {
      const value = state.userInfo?.pwMessage;
      if (typeof value === 'undefined') return '';
      return value;
    },
    userName(state) {
      const value = state.userInfo?.RealName;
      if (typeof value === 'undefined') return '';
      return value;
    },
    userMaxWin(state) {
      const win = state.siteInfo?.MaxWin;
      const currency = state.userInfo?.Currency;
      if (typeof win === 'undefined' || typeof currency === 'undefined' || !(currency in win)) {
        return 0;
      }
      return win[currency];
    },
    isSessionOk(state) {
      return state.sessionToken && state.sessionAccount;
    },
    themeInfo(state) {
      return `${state.themeMode}${state.themeKey}`;
    },
  },
  mutations: {
    //
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    showLoading(state) {
      state.isLoading = true;
    },
    hideLoading(state) {
      state.isLoading = false;
    },
    setSiteInfo(state, info) {
      const { SiteSkin = '', SiteTitle } = info;
      state.siteInfo = info;
      state.siteLabels = SiteSkin.split(',').map((label) => label.trim().toLowerCase());
      if (SiteTitle) {
        document.title = SiteTitle;
      }
    },
    setUserInfo(state, info) {
      state.userInfo = info;
    },
    setUserBalance(state, balance) {
      state.userBalance = balance;
    },
    setUserTickets(state, tickets) {
      state.userTickets = tickets;
    },
    setUserUnread(state, unread) {
      state.userUnread = unread;
    },
    setUserName(state, name) {
      if (state.userInfo !== null) {
        state.userInfo.RealName = name;
      }
    },
    clearUser(state) {
      state.userInfo = null;
      state.userBalance = '';
      state.userTickets = 0;
      state.userUnread = 0;
    },
    setSessionToken(state, token) {
      setSessionToken(token);
      state.sessionToken = token;
    },
    setSessionAccount(state, account) {
      setSessionAccount(account);
      state.sessionAccount = account;
    },
    clearSession(state) {
      clearSessionToken();
      clearSessionAccount();
      state.sessionToken = '';
      state.sessionAccount = '';
    },
    setI18nLocale(state, locale) {
      const newLocale = setI18nLocale(locale);
      if (state.i18nLocale !== newLocale) {
        state.i18nLocale = newLocale;
      }
    },
    setThemeMode(state, mode) {
      const newMode = setThemeMode(mode);
      if (state.themeMode !== newMode) {
        state.themeMode = newMode;
      }
    },
    setThemeKey(state, key) {
      const newKey = setThemeKey(key);
      if (state.themeKey !== newKey) {
        state.themeKey = newKey;
      }
    },
  },
  actions: {
    initSite({ commit, getters }) {
      return getSiteInfoApi().then((response) => {
        const { data } = response;
        if (data) {
          if (Array.isArray(data)) {
            Promise.reject(response);
          } else {
            const info = data;
            commit('setSiteInfo', info);
            return getters.isSiteMaintain;
          }
        } else {
          Promise.reject(response);
        }
      });
    },
    updateUser({ commit }) {
      return getUserInfoApi().then((response) => {
        const { data } = response;
        if (data) {
          if (Array.isArray(data)) {
            Promise.reject(response);
          } else {
            const info = data;
            commit('setUserInfo', info);
          }
        } else {
          Promise.reject(response);
        }
      });
    },
    updateUserBalance({ commit }) {
      return getUserBalanceApi().then((response) => {
        const { data } = response;
        if (data) {
          if (Array.isArray(data)) {
            Promise.reject(response);
          } else {
            const { Balance: balance = '', TicketCount: tickets = 0, MesCount: unread = 0 } = data;
            commit('setUserBalance', balance);
            commit('setUserTickets', tickets);
            commit('setUserUnread', unread);
          }
        } else {
          Promise.reject(response);
        }
      });
    },
    changeUserPassword({ commit }, { newPassword, oldPassword }) {
      return putUserPasswordApi({ newPassword, oldPassword });
    },
    changeUserName({ commit }, name) {
      return putUserNameApi(name).then(() => {
        commit('setUserName', name);
      });
    },
    login({ commit, getters }, { account, password }) {
      return postLoginApi({ account, password }).then((response) => {
        const { data } = response;
        const token = data?.loginID;
        const info = data?.mb;
        const account = info?.mbID;
        commit('setSessionToken', token);
        commit('setSessionAccount', account);
        commit('setUserInfo', info);
        localStorage.setItem(LOGIN, token);
        return getters.isUserPasswordExpire;
      });
    },
    tokenLogin({ commit }, token) {
      return postTokenLoginApi(token).then((response) => {
        const { data } = response;
        const token = data?.loginID;
        const info = data?.mb;
        const account = info?.mbID;
        commit('setSessionToken', token);
        commit('setSessionAccount', account);
        commit('setUserInfo', info);
        localStorage.setItem(LOGIN, token);
      });
    },
    logout({ state, commit }) {
      const { sessionToken } = state;
      if (!sessionToken) return;
      return postLogoutApi().then(() => {
        commit('clearSession');
        localStorage.setItem(LOGOUT, sessionToken);
        if (router.currentRoute.name !== loginRoute.name) {
          router.replace(loginRoute);
        }
      });
    },
  },
});

/*
      if (store.state.MBID) {
        rootStore.commit('Setting/clearLeagues');
      }
      store.commit('BetCart/clearCart', null, { root: true });
      store.commit('BetCart/clearLastFirstBetData', null, { root: true });
      store.commit('SetToken', '');
      store.commit('SetMBID', '');
      store.commit('MoreGame/setIsShowMoreGame', null, { root: true });

            if (window.chat) {
        window.chat.reset();
      }
*/
