<template>
  <div id="api-login" class="page">
    <main class="container">
      <p class="content" v-html="message" />
    </main>
  </div>
</template>

<script>
  import { postTokenLoginApi } from '@/api';
  import { setI18nLocale } from '@/i18n';

  export default {
    data() {
      return {
        message: '登入中',
      };
    },
    computed: {
      token() {
        return this.$route?.query?.MemID;
      },
    },
    created() {
      const { token } = this;
      if (token) {
        postTokenLoginApi(token)
          .then((response) => {
            const { code, message, data } = response;
            if (code === 200) {
              const { loginID, mb, Lang } = data;
              const { mbID } = mb;
              if (loginID && mbID) {
                const { commit } = this.$store;
                commit('setSessionToken', loginID);
                commit('setSessionAccount', mbID);
                commit('setUserInfo', mb);
                setI18nLocale(Lang);
                this.$router.replace({ name: 'Games' });
              } else {
                console.warn('api data error: loginID, mb.mbID');
              }
            } else {
              const { returnURL } = data;
              if (returnURL) {
                location.href = returnURL;
              } else {
                this.message = message;
                console.warn('api data error: returnURL');
              }
            }
          })
          .catch((error) => {
            console.error(error);
            const { data } = error;
            const { message } = data;
            const { returnURL } = data.data;
            if (returnURL) {
              location.href = returnURL;
            }
            this.message = message;
          });
      } else {
        this.message = '登入失敗';
      }
    },
  };
</script>

<style lang="scss" scoped>
  #api-login {
    background-color: #1a2b3c;
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 18px;
    color: #eee;
    transform: translate(-50%, -50%);
  }
</style>
