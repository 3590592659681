import {
  getGameCatApi,
  getGameTypeApi,
  getGameMenuApi,
  getGameDetailApi,
  getGameDetailSmallApi,
  getGameDetailLimitApi,
  getGameResultApi,
  getGameLeagueApi,
  getAnnouncementApi,
} from '@/api/game';
import {
  getSChatMessageApi,
  getSChatWelcomeApi,
  getSChatUnreadApi,
  postSChatReadApi,
  postSChatTextApi,
  postSChatFileApi,
} from '@/api/schat';
import {
  getSportNewsApi,
  getSportNewsContentApi,
  getSportLiveApi,
  getMovieTheaterApi,
} from '@/api/sport';
import i18n from '@/i18n';
import rootStore from '@/store';
import { getMenuIconByCatID, getBoardImageByCatId, getColorByCatId } from '@/utils/SportLib';
import { favoriteCatID, hotCatID, championCatID } from '@/config/sport.js';

export default {
  namespaced: true,
  state: {
    GameTypeList: [],
    CatList: [],
    CatMapData: {},
    OddsAdj: {},
    FullMenuList: [],
    GameList: [],
    selectGameType: null,
    selectCatID: null,
    selectWagerTypeKey: null,
    selectLeagueIDs: [],
    selectLeagueIDs2: [],
    betInfo: [],
    isCallGameDetailAPI: false,
    boldFilterType: true,
    isQuickBet: {
      isEnable: false,
      amount: 100,
    },
    isShowService: false,
    readAnnouncements: null,
    announcements: [],
    detailTimeoutID: null,
    isShowGameBet: false,
  },
  getters: {
    selectGameTypeName(state) {
      const { GameTypeList, selectGameType } = state;
      if (GameTypeList.length === 0 || selectGameType === null) return '';
      const typeItem = GameTypeList.find((item) => item.key === selectGameType);
      return typeItem === null ? '' : typeItem.value;
    },
    selectWagerTypeName(state) {
      const { selectGameType, FullMenuList } = state;
      if (selectGameType === null || FullMenuList.length === 0) return '';
      const menuItem = FullMenuList.find((item) => item.GameType === selectGameType);
      const selectMenuCatList = !menuItem ? [] : menuItem.LeftMenu.item;
      const { selectCatID, selectWagerTypeKey } = state;
      if (selectMenuCatList.length === 0 || !selectCatID || !selectWagerTypeKey) return '';
      const catItem = selectMenuCatList.find((item) => item.catid === selectCatID);
      if (catItem === null) return '';
      const wagerItem = catItem.Items.find((item) => item.WagerTypeKey === selectWagerTypeKey);
      return !wagerItem ? '' : wagerItem.WagerTypeName;
    },
    selectMenuCatList(state) {
      const { selectGameType, FullMenuList } = state;
      if (selectGameType === null || FullMenuList.length === 0) return [];
      const menuItem = FullMenuList.find((item) => item.GameType === selectGameType);
      return !menuItem ? [] : menuItem.LeftMenu.item;
    },
    LeagueList(state) {
      if (state.GameList.length !== 0) {
        const leagueMap = state.GameList[0].Items.List.reduce((sum, it) => {
          const hasKey = Object.prototype.hasOwnProperty.call(sum, it.LeagueID);
          if (!hasKey) {
            sum[it.LeagueID] = {
              LeagueID: it.LeagueID,
              LeagueName: it.LeagueNameStr,
            };
          }
          return sum;
        }, {});
        return Object.keys(leagueMap).reduce((sum, it) => {
          let isSelect = true;
          if (state.selectLeagueIDs.length === 0) {
            isSelect = true;
          } else {
            isSelect = state.selectLeagueIDs.indexOf(parseInt(it)) !== -1;
          }
          return [...sum, ...[{ ...leagueMap[it], ...{ isSelect } }]];
        }, []);
      } else {
        return [];
      }
    },
    GameListFilterBySelectLeague(state) {
      if (state.GameList.length !== 0) {
        // 如果沒選擇聯盟
        if (
          state.selectCatID === favoriteCatID ||
          state.selectCatID === hotCatID ||
          state.selectLeagueIDs.length === 0
        ) {
          return state.GameList;
        } else {
          // 如果有選擇聯盟
          const newGameList = JSON.parse(JSON.stringify(state.GameList));
          newGameList[0].Items.List = newGameList[0].Items.List.filter((it) => {
            return state.selectLeagueIDs.indexOf(it.LeagueID) !== -1;
          });
          return newGameList;
        }
      } else {
        return [];
      }
    },
    hasUnreadAnnouncements(state) {
      const announcements = state.announcements;
      for (const announcement of announcements) {
        if (!announcement.read) return true;
      }
      return false;
    },
  },
  mutations: {
    setShowGameBet(state, val) {
      state.isShowGameBet = val;
    },
    setGameTypeList(state, val) {
      state.GameTypeList = val;
    },
    setCatList(state, val) {
      state.CatList = val;
    },
    setCatMapData(state, val) {
      state.CatMapData = val;
    },
    setFullMenuList(state, val) {
      state.FullMenuList = val;
    },
    setOddsAdj(state, val) {
      state.OddsAdj = val;
    },
    setGameType(state, val) {
      state.selectGameType = val;
    },
    setCatIDAndGameTypeAndWagerType(state, { selectGameType, selectCatID, selectWagerTypeKey }) {
      state.selectGameType = selectGameType;
      state.selectCatID = selectCatID;
      state.selectWagerTypeKey = selectWagerTypeKey;
    },
    changeCatReset(state, cat) {
      const t = state.selectGameType;
      const c = rootStore.state.Setting.UserSetting.leagues[cat];
      const list = c ? c[t] : null;
      if (list && list.length > 0) {
        state.selectLeagueIDs2 = list;
      } else {
        state.selectLeagueIDs2 = [];
      }
      state.selectLeagueIDs = [];
    },
    setSelectLeagueIDs2(state, val) {
      state.selectLeagueIDs2 = val;
    },
    setSelectLeagueIDs(state, val) {
      state.selectLeagueIDs = val;
    },
    updateSelectedGameList(state) {
      const list = [];
      state.GameList[0].Items.List.forEach((item) => {
        if (state.selectLeagueIDs2.indexOf(item.LeagueID) === -1) {
          list.push(item.LeagueID);
        }
      });
      state.selectLeagueIDs = list;
    },
    setBoldFilterType(state, val) {
      state.boldFilterType = val;
    },
    setBetInfo(state, val) {
      state.betInfo = val;
    },
    setIsShowService(state, val) {
      state.isShowService = val;
    },
    setQuickBetEnable(state, val) {
      state.isQuickBet.isEnable = val;
    },
    setQuickBetAmount(state, val) {
      state.isQuickBet.amount = val;
    },
    clearGameList(state) {
      state.GameList = [];
    },
    setGameList(state, data) {
      const { data: gameList, isFavorite, postData } = data;
      const { WagerTypeKey } = postData;
      const temTeamList = [];

      const newGameList = gameList.map((gameData) => {
        const { CatID, CatName, Items } = gameData;
        const { BestHead: headList, List: leagueList } = Items;
        let fix = 0;
        let newHeadList;
        let newHeadList1;
        let newHeadList2;
        let hasMoreCount = false;

        switch (CatID) {
          // 足球
          // 世界盃 冠軍盃
          case 1:
          case 31:
            switch (WagerTypeKey) {
              case 2: // 角球
                fix = 2;
                break;
              case 3: // 波膽
                fix = 3;
                break;
              case 4: // 入球數
                fix = 4;
                break;
              case 5: // 半全場
                fix = 5;
                break;
            }
            break;
          // 籃球
          case 3:
          case 102:
            switch (WagerTypeKey) {
              // 單隊總分
              case 6:
                fix = 6;
                break;
            }
            break;
        }

        switch (fix) {
          case 2:
            newHeadList1 = headList.map((headData) => {
              const WagerGrpIDs = headData.WagerGrpIDs.filter((id) => [0, 10, 20].includes(id));
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: headData.Name,
              };
            });
            newHeadList2 = headList.map((headData) => {
              const WagerGrpIDs = headData.WagerGrpIDs.filter((id) => [1, 11, 21].includes(id));
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: headData.Name2,
              };
            });
            newHeadList = [...newHeadList1, ...newHeadList2];
            break;
          case 3:
            newHeadList = [];
            break;
          case 4:
            newHeadList1 = headList
              .filter((headData) => [0, 10, 20].includes(headData.WagerGrpIDs[0]))
              .map((headData) => {
                return {
                  WagerTypeIDs: headData.WagerTypeIDs,
                  WagerGrpIDs: headData.WagerGrpIDs,
                  WagerGrpName: headData.WagerGrpName,
                  OULine: headData.OULine,
                  showName: headData.Name,
                };
              });
            newHeadList2 = headList
              .filter((headData) => [1, 11, 21].includes(headData.WagerGrpIDs[0]))
              .map((headData) => {
                return {
                  WagerTypeIDs: headData.WagerTypeIDs,
                  WagerGrpIDs: headData.WagerGrpIDs,
                  WagerGrpName: headData.WagerGrpName,
                  OULine: headData.OULine,
                  showName: headData.Name,
                };
              });
            newHeadList = [...newHeadList1, ...newHeadList2];
            break;
          case 5:
            newHeadList = headList.map((headData) => {
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: headData.WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                OULine: headData.OULine,
                showName: headData.Name,
              };
            });
            break;
          // 籃球 單隊總分
          case 6:
            newHeadList1 = headList.map((headData) => {
              const WagerGrpIDs = headData.WagerGrpIDs.filter((id) => [0, 10, 20].includes(id));
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: headData.Name,
              };
            });
            newHeadList2 = headList.map((headData) => {
              const WagerGrpIDs = headData.WagerGrpIDs.filter((id) => [1, 11, 21].includes(id));
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: headData.Name,
              };
            });
            newHeadList = [...newHeadList1, ...newHeadList2];
            break;
          default:
            newHeadList = headList.map((headData) => {
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: headData.WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: headData.Name,
              };
            });
        }

        const newLeagueList = leagueList
          .filter((leagueData) => {
            const { Team: teamList } = leagueData;
            const newTeamList = teamList.filter((teamData) => teamData.EvtStatus === 1);
            return newTeamList.length > 0;
          })
          .map((leagueData, index) => {
            return { ...leagueData, id: index, CatNameStr: CatName };
          })
          .map((leagueData) => {
            const { Team: teamList } = leagueData;
            const newLeagueData = JSON.parse(JSON.stringify(leagueData));
            const newTeamList = teamList.map((teamData) => {
              const { Wager: teamWager } = teamData;
              const newTeamData = JSON.parse(JSON.stringify(teamData));
              const wagerList = JSON.parse(JSON.stringify(teamWager));
              let newWagerList;
              let newWagerList1;
              let newWagerList2;

              switch (fix) {
                case 2:
                  newWagerList1 = new Array(newHeadList1.length).fill({
                    isNoData: true,
                  });
                  newWagerList2 = new Array(newHeadList2.length).fill({
                    isNoData: true,
                  });

                  newHeadList1.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    wagerList.forEach((wagerData) => {
                      if (
                        headTypeList.includes(wagerData.WagerTypeID) &&
                        headGroupList.includes(wagerData.WagerGrpID)
                      ) {
                        newWagerList1[headIndex] = wagerData;
                      }
                    });
                  });
                  newHeadList2.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    wagerList.forEach((wagerData) => {
                      if (
                        headTypeList.includes(wagerData.WagerTypeID) &&
                        headGroupList.includes(wagerData.WagerGrpID)
                      ) {
                        newWagerList2[headIndex] = wagerData;
                      }
                    });
                  });
                  newWagerList = [...newWagerList1, ...newWagerList2];
                  newTeamData.Wager = newWagerList;
                  newTeamData.Wager1 = newWagerList1;
                  newTeamData.Wager2 = newWagerList2;
                  break;
                case 3:
                  break;
                case 4:
                  newWagerList1 = new Array(newHeadList1.length).fill({
                    isNoData: true,
                  });
                  newWagerList2 = new Array(newHeadList2.length).fill({
                    isNoData: true,
                  });
                  newHeadList1.forEach((headData, headIndex) => {
                    const {
                      WagerTypeIDs: headTypeList,
                      WagerGrpIDs: headGroupList,
                      OULine: headLine,
                    } = headData;
                    const headGroup = headGroupList[0];
                    wagerList.forEach((wagerData) => {
                      if (headGroup === 128) {
                        if (headTypeList.includes(wagerData.WagerTypeID)) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList1[headIndex] = newWagerData;
                            }
                          });
                        }
                      } else {
                        if (
                          headTypeList.includes(wagerData.WagerTypeID) &&
                          headGroupList.includes(wagerData.WagerGrpID)
                        ) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList1[headIndex] = newWagerData;
                            }
                          });
                        }
                      }
                    });
                  });
                  newHeadList2.forEach((headData, headIndex) => {
                    const {
                      WagerTypeIDs: headTypeList,
                      WagerGrpIDs: headGroupList,
                      OULine: headLine,
                    } = headData;
                    const headGroup = headGroupList[0];
                    wagerList.forEach((wagerData) => {
                      if (headGroup === 128) {
                        if (headTypeList.includes(wagerData.WagerTypeID)) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList2[headIndex] = newWagerData;
                            }
                          });
                        }
                      } else {
                        if (
                          headTypeList.includes(wagerData.WagerTypeID) &&
                          headGroupList.includes(wagerData.WagerGrpID)
                        ) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList2[headIndex] = newWagerData;
                            }
                          });
                        }
                      }
                    });
                  });
                  newWagerList = [...newWagerList1, ...newWagerList2];
                  newTeamData.Wager = newWagerList;
                  newTeamData.Wager1 = newWagerList1;
                  newTeamData.Wager2 = newWagerList2;
                  break;
                case 5:
                  newWagerList = new Array(newHeadList.length).fill({
                    isNoData: true,
                  });
                  newHeadList.forEach((headData, headIndex) => {
                    const {
                      WagerTypeIDs: headTypeList,
                      WagerGrpIDs: headGroupList,
                      OULine: headLine,
                    } = headData;
                    const headGroup = headGroupList[0];
                    wagerList.forEach((wagerData) => {
                      if (headGroup === 128) {
                        if (headTypeList.includes(wagerData.WagerTypeID)) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList[headIndex] = newWagerData;
                            }
                          });
                        }
                      } else {
                        if (
                          headTypeList.includes(wagerData.WagerTypeID) &&
                          headGroupList.includes(wagerData.WagerGrpID)
                        ) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList[headIndex] = newWagerData;
                            }
                          });
                        }
                      }
                    });
                  });
                  newTeamData.Wager = newWagerList;
                  break;
                case 6:
                  newWagerList1 = new Array(newHeadList1.length).fill({
                    isNoData: true,
                  });
                  newWagerList2 = new Array(newHeadList2.length).fill({
                    isNoData: true,
                  });

                  newHeadList1.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    wagerList.forEach((wagerData) => {
                      if (
                        headTypeList.includes(wagerData.WagerTypeID) &&
                        headGroupList.includes(wagerData.WagerGrpID)
                      ) {
                        newWagerList1[headIndex] = wagerData;
                        wagerData.Odds.sort((odds1, odds2) => {
                          return odds1.HdpPos - odds2.HdpPos;
                        });
                      }
                    });
                  });
                  newHeadList2.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    wagerList.forEach((wagerData) => {
                      if (
                        headTypeList.includes(wagerData.WagerTypeID) &&
                        headGroupList.includes(wagerData.WagerGrpID)
                      ) {
                        newWagerList2[headIndex] = wagerData;
                        wagerData.Odds.sort((odds1, odds2) => {
                          return odds1.HdpPos - odds2.HdpPos;
                        });
                      }
                    });
                  });
                  newWagerList = [...newWagerList1, ...newWagerList2];
                  newTeamData.Wager = newWagerList;
                  newTeamData.Wager1 = newWagerList1;
                  newTeamData.Wager2 = newWagerList2;
                  break;
                default:
                  newWagerList = new Array(newHeadList.length).fill({
                    isNoData: true,
                  });
                  newHeadList.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    const headGroup = headGroupList[0];
                    wagerList.forEach((wagerData) => {
                      if (headGroup === 128) {
                        if (headTypeList.includes(wagerData.WagerTypeID)) {
                          newWagerList[headIndex] = wagerData;
                        }
                      } else {
                        if (
                          headTypeList.includes(wagerData.WagerTypeID) &&
                          headGroupList.includes(wagerData.WagerGrpID)
                        ) {
                          newWagerList[headIndex] = wagerData;
                        }
                      }
                    });
                  });
                  newTeamData.Wager = newWagerList;
              }

              const newDrewOdds = [];
              teamWager.forEach((wagerData) => {
                const { Odds: oddsList } = wagerData;
                const odds = oddsList[0];
                newDrewOdds.push(odds.DrewOdds);
              });
              newTeamData.hasDrewOdds = newDrewOdds.some((oddsData) => oddsData !== undefined);

              if (teamData.MoreCount !== -1) {
                hasMoreCount = true;
              }

              temTeamList.push(teamData.EvtID);

              return newTeamData;
            });

            newLeagueData.Team = newTeamList;

            return newLeagueData;
          });

        return {
          ...gameData,
          Items: {
            BestHead: [newHeadList, newHeadList1, newHeadList2],
            List: newLeagueList,
            hasMoreCount,
          },
        };
      });

      state.GameList = newGameList;

      if (isFavorite) {
        rootStore.commit('Setting/setFavorites', temTeamList);
      }
    },
    updateGameList(state, { updateData, isUpdateFromOtherStore = false }) {
      const { GameList } = state;
      if (GameList.length === 0) return;
      let force = false;

      const notFindData = [];

      updateData.forEach((updateData) => {
        let isFind = false;
        GameList.every((GameData) => {
          const gameListIndex = GameData.Items.List.findIndex(
            (LeagueData) => LeagueData.LeagueID === updateData.LeagueID
          );

          if (gameListIndex !== -1) {
            try {
              GameData.Items.List[gameListIndex].Team.every((teamData, teamIndex) => {
                return teamData.Wager.every((wagerData) => {
                  if (wagerData?.isNoData) {
                    return true;
                  } else {
                    return wagerData.Odds.every((oddData) => {
                      if (oddData.GameID === updateData.GameID) {
                        isFind = true;
                        //
                        if (updateData.EvtStatus !== 1) {
                          console.warn(
                            'some data is disable!!',
                            teamData.EvtStatus,
                            updateData.EvtStatus
                          );
                        }
                        oddData.HdpPos = updateData.HdpPos;
                        oddData.HomeHdp = updateData.HomeHdp;
                        oddData.AwayHdp = updateData.AwayHdp;
                        oddData.HomeHdpOdds = updateData.HomeHdpOdds;
                        oddData.AwayHdpOdds = updateData.AwayHdpOdds;
                        oddData.OULine = updateData.OULine;
                        oddData.OverOdds = updateData.OverOdds;
                        oddData.UnderOdds = updateData.UnderOdds;
                        oddData.HomeOdds = updateData.HomeOdds;
                        oddData.AwayOdds = updateData.AwayOdds;
                        oddData.DrewOdds = updateData.DrewOdds;
                        oddData.Status = updateData.Status;

                        // 強制更新
                        // console.log(updateData.GameID, updateData.EvtStatus, updateData.EvtStatus);
                        if ([1, -4].includes(updateData.EvtStatus)) {
                          if (![1, -1].includes(updateData.Status)) {
                            // console.log(new Date().toISOString(), 'force', updateData);
                            force = true;
                          }
                        }

                        //
                        teamData.EvtStatus = updateData.EvtStatus;
                        return false;
                      } else {
                        return true;
                      }
                    });
                  }
                });
              });

              // 只有移除 所有 team EvtStatus 都不為 1 時的 league

              // 檢查League Data 的Team 是否EvtStatus 都為1,如果都是非1的話 就移除此League資料
              const EvtStatusEnableList = GameData.Items.List[gameListIndex].Team.filter(
                (it) => it.EvtStatus === 1
              );
              if (EvtStatusEnableList.length === 0) {
                GameData.Items.List.splice(gameListIndex, 1);
              }
            } catch (err) {
              console.error('err:', err);
            }
            return false;
          } else {
            return true;
          }
        });

        if (!isFind) {
          // console.log(new Date().toISOString(), 'not found', updateData);
          notFindData.push(updateData);
        }
      });

      // 有主盤口沒有的遊戲資料時 更新主盤口

      // 自己store呼叫的 dispatch
      if (!isUpdateFromOtherStore) {
        // 更新數據有,但是Table卻沒有時,要重新打detail API
        if (notFindData.length !== 0) {
          // console.warn('update的資料,detail資料不存在,即將重打detail API', notFindData);
          state.isCallGameDetailAPI = !state.isCallGameDetailAPI;
        }
        rootStore.commit('MoreGame/updateMoreGameData', {
          isUpdateFromOtherStore: true,
          updateData,
        });
      }
      if (force) {
        state.isCallGameDetailAPI = !state.isCallGameDetailAPI;
      }
    },
    updateTeamData(state, { updateData }) {
      if (state.GameList.length !== 0) {
        updateData.forEach((updateData) => {
          state.GameList.every((GameData) => {
            const gameListIndex = GameData.Items.List.findIndex(
              (LeagueData) => LeagueData.LeagueID === updateData.LeagueID
            );

            if (gameListIndex !== -1) {
              try {
                GameData.Items.List[gameListIndex].Team.every((teamData, teamIndex) => {
                  if (
                    teamData.AwayID === updateData.AwayID &&
                    teamData.HomeID === updateData.HomeID
                  ) {
                    teamData = { ...teamData, ...updateData };
                    GameData.Items.List[gameListIndex].Team[teamIndex] = teamData;
                    return false;
                  } else {
                    return true;
                  }
                });
              } catch (err) {
                console.error('updateTeamData:', err);
              }
              return false;
            } else {
              return true;
            }
          });
        });
      }
    },
    updateMoreCount(state, { updateData }) {
      if (state.GameList.length !== 0) {
        updateData.forEach((updateData) => {
          state.GameList.every((GameData) => {
            return GameData.Items.List.every((LeagueData) => {
              return LeagueData.Team.every((teamData) => {
                if (teamData.EvtID === updateData.EvtID) {
                  teamData.MoreCount = updateData.Count;
                  return false;
                } else {
                  return true;
                }
              });
            });
          });
        });
      }
    },
    setDetailTimeoutID(state, ID) {
      state.detailTimeoutID = ID;
    },
    readAnnouncement(state, announcement) {
      if (state.readAnnouncements === null) {
        let Announcements = [];
        const data = localStorage.getItem('Announcements');
        if (data !== null) {
          Announcements = JSON.parse(data);
        }
        state.readAnnouncements = Announcements;
      }
      if (!announcement.read) {
        announcement.read = true;
        state.readAnnouncements.push(announcement.Id);
        if (state.readAnnouncements.length > 100) {
          state.readAnnouncements.sort((a, b) => b - a);
          state.readAnnouncements.length = 100;
        }
        localStorage.setItem('Announcements', JSON.stringify(state.readAnnouncements));
      }
    },
  },
  actions: {
    updateGameTypeList({ commit, dispatch }) {
      return getGameTypeApi()
        .then((response) => {
          const { data } = response;
          if (!data || data.length === 0) {
          } else {
            commit('setGameTypeList', data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateGameCatList({ commit }) {
      return getGameCatApi(i18n.locale).then((res) => {
        /* res.push({
          CatID: favoriteCatID,
          GroupCatIDs: [favoriteCatID],
          GameScoreRefresh: false,
          EvtItem: [],
        }); */
        /*  res.push({
          CatID: hotCatID,
          GroupCatIDs: [hotCatID],
          GameScoreRefresh: false,
          EvtItem: [],
        }); */

        res.forEach((catData) => {
          const { CatID } = catData;
          catData.icon = getMenuIconByCatID(CatID);
          catData.color = getColorByCatId(CatID);
          catData.background = getBoardImageByCatId(CatID);
        });

        const catMap = res.reduce((map, catData) => {
          catData.GroupCatIDs.forEach((id) => {
            map = {
              ...map,
              ...{
                [id]: catData,
              },
            };
          });
          return map;
        }, {});

        commit('setCatList', res);
        commit('setCatMapData', catMap);
      });
    },
    updateGameMenuList({ state, commit }, clear = false) {
      if (clear) {
        commit('setFullMenuList', []);
      }
      return getGameMenuApi()
        .then((response) => {
          const { data } = response;
          const { list, Default, OddsAdj } = data;

          // 冠軍放最前面
          list.forEach((item) => {
            // eslint-disable-next-line no-unused-expressions
            item?.LeftMenu?.item?.sort((a, b) => {
              if (a.catid === championCatID) return -1;
              else if (b.catid === championCatID) return 1;
              else return 0;
            });
          });

          commit('setFullMenuList', list);

          const { selectGameType, selectCatID } = state;
          if (selectGameType === null && !selectCatID) {
            const { GameType, catid, WagerTypeKey } = Default;
            commit('setCatIDAndGameTypeAndWagerType', {
              selectGameType: GameType,
              selectCatID: catid,
              selectWagerTypeKey: WagerTypeKey,
            });
          }

          const OddsAdjMap = OddsAdj.reduce(
            (map, item) => ({ ...map, [item.CatID]: item.ParlayAdj }),
            {}
          );
          commit('setOddsAdj', OddsAdjMap);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateGameDetail({ rootState, state, commit, dispatch }, { clear }) {
      const { selectGameType, selectCatID, selectWagerTypeKey, CatMapData, detailTimeoutID } =
        state;
      if (detailTimeoutID) {
        clearTimeout(detailTimeoutID);
        commit('setDetailTimeoutID', null);
      }
      if (selectCatID === favoriteCatID) {
        const EvtIDs = rootStore.state.Setting.UserSetting.favorites.join(',');
        const postData = {
          FavoritesModel: true,
          GameType: selectGameType,
          EvtIDs,
        };
        if (clear) {
          commit('clearGameList');
        }
        return getGameDetailApi(postData).then((response) => {
          const { data } = response;
          commit('setGameList', {
            data,
            isFavorite: true,
            postData,
          });
          const timeoutID = setTimeout(() => {
            if (sessionStorage.getItem('Token')) {
              commit('setDetailTimeoutID', null);
              dispatch('updateGameDetail', { clear: false });
            }
          }, 300000);
          commit('setDetailTimeoutID', timeoutID);
        });
      } else if (selectCatID === hotCatID) {
        const postData = {
          GameType: selectGameType,
          CatID: selectCatID,
          WagerTypeKey: selectWagerTypeKey,
        };
        if (!postData.WagerTypeKey) {
          delete postData.WagerTypeKey;
        }
        if (clear) {
          commit('clearGameList');
        }
        return getGameDetailApi(postData).then((response) => {
          const { data } = response;
          data.forEach((item) => {
            item.CatName = item.CatName.split(' ')[0];
          });
          commit('setGameList', {
            data,
            isFavorite: false,
            postData,
          });
          const timeoutID = setTimeout(() => {
            if (sessionStorage.getItem('Token')) {
              commit('setDetailTimeoutID', null);
              dispatch('updateGameDetail', { clear: false });
            }
          }, 300000);
          commit('setDetailTimeoutID', timeoutID);
        });
      } else {
        const show = rootState.Setting.UserSetting.tableSort;
        const postData = {
          GameType: selectGameType,
          CatID: selectCatID,
          WagerTypeKey: selectWagerTypeKey,
        };
        const payload = {
          ...postData,
          show,
        };
        if (!payload.WagerTypeKey) {
          delete payload.WagerTypeKey;
        }
        if (clear) {
          commit('clearGameList');
        }
        return getGameDetailApi(payload).then((response) => {
          const { data } = response;
          if (data.List.length) {
            const newData = [
              {
                CatID: selectCatID,
                CatName: CatMapData[selectCatID].Name,
                Items: data,
              },
            ];
            commit('setGameList', {
              data: newData,
              isFavorite: false,
              postData,
            });
            commit('updateSelectedGameList');
            const timeoutID = setTimeout(() => {
              if (sessionStorage.getItem('Token')) {
                commit('setDetailTimeoutID', null);
                dispatch('updateGameDetail', { clear: false });
              }
            }, 300000);
            commit('setDetailTimeoutID', timeoutID);
            // dispatch('GetMainBetInfo', postData);
          }
        });
      }
    },
    updateGameDetailSmall({ rootState, state, commit }) {
      const { selectGameType, selectCatID, selectWagerTypeKey } = state;
      if (selectCatID === favoriteCatID) {
        const EvtIDs = rootState.Setting.UserSetting.favorites.join(',');
        return getGameDetailSmallApi({
          FavoritesModel: true,
          GameType: selectGameType,
          EvtIDs,
        }).then((response) => {
          const { data } = response;
          const { List, GameScoreHead, MoreCoutToEvtID } = data;
          if (List?.length) {
            commit('updateGameList', {
              updateData: List,
            });
          }
          if (GameScoreHead?.length) {
            commit('updateTeamData', {
              updateData: GameScoreHead,
            });
          }
          if (MoreCoutToEvtID?.length) {
            commit('updateMoreCount', {
              updateData: MoreCoutToEvtID,
            });
          }
        });
      } else {
        const postData = {
          GameType: selectGameType,
          CatID: selectCatID,
          WagerTypeKey: selectWagerTypeKey,
        };
        const payload = {
          ...postData,
        };
        if (!payload.WagerTypeKey) {
          delete payload.WagerTypeKey;
        }
        return getGameDetailSmallApi(payload).then((response) => {
          const { data } = response;

          const { List, GameScoreHead, MoreCoutToEvtID } = data;
          if (List?.length) {
            commit('updateGameList', {
              updateData: List,
            });
          }
          if (GameScoreHead?.length) {
            commit('updateTeamData', {
              updateData: GameScoreHead,
            });
          }
          if (MoreCoutToEvtID?.length) {
            commit('updateMoreCount', {
              updateData: MoreCoutToEvtID,
            });
          }
        });
      }
    },
    GetMainBetInfo(store, postData) {
      const newPostData = Object.assign({}, postData);
      if (postData.CatIDs === favoriteCatID) {
        newPostData.EvtIDs = rootStore.state.Setting.UserSetting.favorites.join(',');
        delete newPostData.CatIDs;
      }
      if (!newPostData.WagerTypeKey) {
        delete newPostData.WagerTypeKey;
      }
      return getGameDetailLimitApi(newPostData).then((res) => {
        store.state.betInfo = res.data;
      });
    },
    GetAnnouncement(store) {
      return new Promise((resolve, reject) => {
        return getAnnouncementApi({
          sla: true,
        }).then((res) => {
          if (store.state.readAnnouncements === null) {
            let Announcements = [];
            const data = localStorage.getItem('Announcements');
            if (data !== null) {
              Announcements = JSON.parse(data);
            }
            store.state.readAnnouncements = Announcements;
          }
          const announcements = store.state.readAnnouncements;
          const announcements_data = res.data;
          for (const announcement of announcements_data) {
            if (announcements.includes(announcement.Id)) {
              announcement.read = true;
            } else {
              announcement.read = false;
            }
          }
          store.state.announcements = announcements_data;
          resolve(res);
        });
      });
    },
    GetSportNews(store) {
      return new Promise((resolve, reject) => {
        return getSportNewsApi().then((res) => {
          resolve(res);
        });
      });
    },
    getSportNewsContentApi(store, postData) {
      return new Promise((resolve, reject) => {
        return getSportNewsContentApi(postData).then((res) => {
          resolve(res);
        });
      });
    },
    GetQAHistory(store, { isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        return getSChatMessageApi(isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    GetCountMes(store, { isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        return getSChatUnreadApi(isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    SendReadMes(store, { isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        return postSChatReadApi(isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    SendQAMessage(store, { message, isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        const postData = {
          Content: message,
        };
        return postSChatTextApi(postData, isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    GetQAFirstMes(store, { isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        return getSChatWelcomeApi(isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    SendQAFile(store, { base64File, name, isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        const postData = {
          Content: base64File,
          FileName: name,
        };
        return postSChatFileApi(postData, isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    GetGameResult(store, { CatID, ScheduleTime, LeagueIDs, EvtID, ItemKey }) {
      return new Promise((resolve, reject) => {
        let postData = {};
        if (EvtID) {
          postData = { EvtID };
        } else {
          postData = {
            CatID,
            LeagueIDs,
            ScheduleTime,
            ItemKey,
          };
        }
        return getGameResultApi(postData)
          .then(async (res) => {
            resolve(res);
          })
          .catch(reject);
      });
    },
    GetGameResultLeagues(store, postData = {}) {
      return new Promise((resolve, reject) => {
        return getGameLeagueApi(postData)
          .then(async (res) => resolve(res))
          .catch(reject);
      });
    },
    GetLiveURL(store) {
      return new Promise((resolve, reject) => {
        return getSportLiveApi().then((res) => {
          resolve(res);
        });
      });
    },
    GetMovieURL(store) {
      return new Promise((resolve, reject) => {
        return getMovieTheaterApi().then((res) => {
          resolve(res);
        });
      });
    },
  },
};
