<template>
  <div id="maintain">
    <div class="messageBlock">
      <div class="logo">
        <img src="@/assets/mainTain.gif" />
      </div>
      <div class="message">
        {{ MainTain.Message }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MainTain',
    computed: {
      MainTain() {
        return this.$store.state.siteInfo.MainTain;
      },
    },
  };
</script>

<style lang="scss" scoped>
  #MainTain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .messageBlock {
      margin-top: -150px;
      .logo {
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          max-width: 500px;
        }
      }
      .message {
        font-size: 2rem;
        padding: 0px 20px;
      }
    }
  }
</style>
