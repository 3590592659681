import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementTWLocale from 'element-ui/lib/locale/lang/zh-TW';
import elementCNLocale from 'element-ui/lib/locale/lang/zh-CN';
import elementENLocale from 'element-ui/lib/locale/lang/en';
import zhTW_Locale from './locales/zh-TW';
import zhCN_Locale from './locales/zh-CN';
import zhEn_Locale from './locales/en';
import { I18N_LOCALE } from '@/config/storage';

Vue.use(VueI18n);

// 'tw', 'cn', 'en', 'jp', 'vi', 'th'
export const i18nLocales = ['tw', 'cn', 'en'];

const messages = {
  tw: {
    ...zhTW_Locale,
    ...elementTWLocale,
  },
  cn: {
    ...zhCN_Locale,
    ...elementCNLocale,
  },
  en: {
    ...zhEn_Locale,
    ...elementENLocale,
  },
};

let locale = localStorage.getItem(I18N_LOCALE);
if (!locale || !i18nLocales.includes(locale)) {
  locale = i18nLocales[0];
}

const i18n = new VueI18n({
  messages,
  locale,
});

export default i18n;

export function getI18nLocale() {
  return i18n.locale;
}

export function setI18nLocale(locale) {
  if (locale && locale !== i18n.locale && i18nLocales.includes(locale)) {
    i18n.locale = locale;
    localStorage.setItem(I18N_LOCALE, locale);
  }
  return i18n.locale;
}
