<template>
  <div
    id="app"
    :data-i18n="i18nLocale"
    :data-theme-mode="themeMode"
    :data-theme-key="themeKey"
    :data-theme="themeInfo"
    v-loading.fullscreen.lock="isLoading"
  >
    <div v-if="!isInitializeOk" />
    <Maintain v-else-if="isSiteMaintain" />
    <router-view v-else />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { redirectRoute, loginRoute, apiLoginRoute, gameRoute } from '@/router';
  import { REDIRECTED } from '@/config/session';
  import { LOGIN, LOGOUT } from '@/config/storage';
  import { isMobileDevice } from '@/config/app';
  import { gotoPcHost, isMobileHost, gotoMobileHost } from '@/config/host';
  import Maintain from '@/components/Maintain';
  import { appVersion, isMobileMode } from '@/config';

  export default {
    components: {
      Maintain,
    },
    data() {
      return {
        isInitializeOk: false,
      };
    },
    computed: {
      ...mapState(['isLoading', 'i18nLocale', 'themeMode', 'themeKey']),
      ...mapGetters(['isSiteMaintain', 'themeInfo']),
    },
    beforeCreate() {
      if (isMobileMode) {
        console.log(appVersion, 'mobile');
      } else {
        console.log(appVersion, 'pc');
      }

      const { $store, $route, $router } = this;
      const { getters, commit, dispatch } = $store;
      const routeName = $route.name;

      if (routeName === redirectRoute.name) {
        sessionStorage.setItem(REDIRECTED, true);
        const { t, a } = this.$route.query;
        if (t && a) {
          commit('setSessionToken', t);
          commit('setSessionAccount', a);
        } else {
          commit('clearSession');
        }
      } else if (routeName === apiLoginRoute.name) {
        sessionStorage.setItem(REDIRECTED, true);
        commit('clearSession');
      } else if (!sessionStorage.getItem(REDIRECTED)) {
        sessionStorage.setItem(REDIRECTED, true);
        if (isMobileDevice()) {
          if (!isMobileHost()) {
            gotoMobileHost();
            return;
          }
        } else {
          if (isMobileHost()) {
            gotoPcHost();
            return;
          }
        }
      }

      window.addEventListener(
        'storage',
        (event) => {
          if (event.key === LOGIN) {
            const { sessionToken } = this.$store.state;
            // 使用者多開時, 以 storage 事件登出不同的使用者
            if (sessionToken && event.newValue && sessionToken !== event.newValue) {
              this.$store.commit('showLoading');
              this.$store
                .dispatch('logout')
                .catch((error) => {
                  console.error(error);
                })
                .finally(() => {
                  this.$store.commit('clearSession');
                  if (this.$router.currentRoute.name !== loginRoute.name) {
                    this.$router.replace(loginRoute).finally(() => {
                      this.$store.commit('hideLoading');
                    });
                  } else {
                    this.$store.commit('hideLoading');
                  }
                });
            }
          }
          // 關閉使用者開啟的相關視窗
          else if (event.key === LOGOUT) {
            const { sessionToken } = this.$store.state;
            // 使用者多開時, 以 storage 事件登出不同的使用者
            if (sessionToken) {
              this.$store.commit('showLoading');
              this.$store.commit('clearSession');
              if (this.$router.currentRoute.name !== loginRoute.name) {
                this.$router.replace(loginRoute).finally(() => {
                  this.$store.commit('hideLoading');
                });
              }
            }
            window.close();
          }
        },
        false
      );

      dispatch('initSite')
        .then((maintain) => {
          if (getters.isSessionOk) {
            if (maintain) {
              commit('clearSession');
            } else {
              return dispatch('updateUser')
                .then(() => {
                  if (
                    routeName === loginRoute.name ||
                    routeName === apiLoginRoute.name ||
                    routeName === redirectRoute.name
                  ) {
                    return $router.replace(gameRoute);
                  }
                })
                .catch((error) => {
                  console.error(error);
                  commit('clearSession');
                  if (this.$route.name !== loginRoute.name) {
                    return $router.replace(loginRoute);
                  }
                });
            }
          } else {
            if (routeName === apiLoginRoute.name) {
            } else if (routeName !== loginRoute.name) {
              return $router.replace(loginRoute);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isInitializeOk = true;
        });
    },
  };
</script>

<style lang="scss" scoped>
  #app {
    position: relative;
    width: 100%;
    height: 100%;
  }
</style>
