// 客服聊天室 service chat
// SChat

import request from './config/request';

// 取得聊天室歡迎訊息
export function getSChatWelcomeApi() {
  return request({
    url: `/GameInfo/QAMes/firstMes`,
    method: 'post',
    auth: true,
  });
}

// 取得聊天室對話訊息
export function getSChatMessageApi() {
  return request({
    url: `/GameInfo/QAMes/LiveList`,
    method: 'post',
    auth: true,
  });
}

// 取得聊天室未讀訊息數
export function getSChatUnreadApi() {
  return request({
    url: `/GameInfo/QAMes/CountMes`,
    method: 'post',
    auth: true,
  });
}

// 讀取聊天室訊息
export function postSChatReadApi() {
  return request({
    url: `/GameInfo/QAMes/ReadMes`,
    method: 'post',
    auth: true,
  });
}

// 發送文字至聊天室
export function postSChatTextApi(data) {
  return request({
    url: `/GameInfo/QAMes/send`,
    method: 'post',
    auth: true,
    data,
  });
}

// 發送檔案至聊天室
export function postSChatFileApi(data) {
  return request({
    url: `/GameInfo/QAMes/sendFile`,
    method: 'post',
    auth: true,
    data,
  });
}
